import React, { useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import "../../../../styles/Pages/Home/Technologies.css";
import TechBoxes from "./Technology Boxes/TechBoxes";

const Technologies = () => {
  const controls = useAnimation();
  const ref = useRef();

  useEffect(() => {
    const element = ref.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            controls.start({ y: 0, opacity: 1 });
          } else {
            controls.start({ y: -50, opacity: 0 });
          }
        });
      },
      { threshold: 0.1 }
    );

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [controls]);
  return (
    <div className="Technologies">
      <motion.div
        className="technologies__wrapper"
        ref={ref}
        initial={{ y: -50, opacity: 0 }}
        animate={controls}
        transition={{ duration: 1 }}
      >
        <div className="technologies__left-side">
          <div className="left__side-content">
            <h1>Smarter Data.</h1>
            <h1>Higher Profits.</h1>
            <span>
              <p>We future-proof your success by leveraging</p>
              <p>proprietary data processes and giving you </p>
              <p>100% campaign transparency.</p>
            </span>

            <button>Check Out Our Tech</button>
          </div>
        </div>
        <div className="technologies__right-side">
            <TechBoxes/>
        </div>
      </motion.div>
    </div>
  );
};

export default Technologies;
