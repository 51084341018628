import epson from "../../../assets/logos/epson.png";
import hermes from "../../../assets/logos/hermes.png";
import redVentures from "../../../assets/logos/rv.png";
import actionHunger from "../../../assets/logos/aah.png";
import animalID from "../../../assets/logos/animal-id.png";
import tutela from "../../../assets/logos/tutela.png";
import sota from "../../../assets/logos/sota.png";
import marketing from "../../../assets/logos/niche.png";
import joolaree from "../../../assets/logos/joolaree.png";
import coddies from "../../../assets/logos/coddies.png";
import conde from "../../../assets/logos/conde.png";

const logos = [
    epson,
    hermes,
    redVentures,
    actionHunger,
    // conde,
    animalID,
    tutela,
    // sota,
    marketing,
    joolaree,
    coddies
  ];

  export default logos