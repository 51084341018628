import React, { useEffect, useRef, useState } from "react";
import "../../../styles/Pages/Home/Main.css";
import video from "../../../assets/main-assets/video4k.mp4";
import poster from "../../../assets/main-assets/videoposter.webp";

const Main = ({handleModalClick}) => {
  const videoRef = useRef(null);
  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const videoPosition = videoRef.current.getBoundingClientRect();

      if (videoPosition.top < window.innerHeight && videoPosition.bottom >= 0) {
        if (videoLoaded) {
          videoRef.current.play();
        }
      } else {
        videoRef.current.pause();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [videoLoaded]);

  return (
    <div className="Main advertiser-main">
      <div className="video-overlay"/>
     
 
      <video
        poster={poster}
        ref={videoRef}
        src={video}
        autoPlay
        loop
        muted
        preload="metadata"
        playsInline
        onLoad={() => setVideoLoaded(true)}
      />
       
      <div className="main__text">
        <h1>Your Strategic Partner in Paid Media Investments</h1>
        <button onClick={handleModalClick}>Get Started</button>
      </div>
    
    </div>
  );
};

export default Main;
