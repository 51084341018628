import Epson from "../../../assets/case study posters/epson-poster.webp";
import Hermes from "../../../assets/case study posters/hermes-poster.webp";
import RV from "../../../assets/case study posters/rv-poster.webp";
import Joolaree from "../../../assets/case study posters/joolaree-poster.webp";
import kingCredit from "../../../assets/case study posters/kingCredit-poster.png"
import Tutela from "../../../assets/case study posters/tutela-poster.png"
import Ortiz from "../../../assets/case study posters/jortiz-poster.png"
// Banners

import epsonBanner from "../../../assets/result banners/epson-result.webp"
import hermesBanner from "../../../assets/result banners/hermes-result.jpg"
import rvBanner from "../../../assets/result banners/rv-result.jpeg"
import joolareeBanner from "../../../assets/result banners/joolaree-result.jpg"
import kingCreditBanner from "../../../assets/result banners/kingCredit-result.jpg"
import tutelaBanner from "../../../assets/result banners/tutela-result.jpg"
import jortizBanner from "../../../assets/result banners/jortiz-result.jpeg"
const casesData = [
    {
      company: "Epson",
      category: "Consumer Electronics",
      imageSrc: Epson,
      hash: "LbI};Z-;-;M|~qs:oft7_2M{Rjt7",
      loaded: false,
      image: epsonBanner,
      bullets: ["Managed 8-figure paid media budget since onboarding.", "Oversaw 26 ad accounts across international business units.","Achieved a 38% and 55% brand lift increase, surpassing benchmarks in North America and Technology sectors.", "Enhanced media mix diversification by 75%.", "Developed comprehensive benchmarks for all business units, objectives, and DMAs."]
    },
    {
      company: "Hermes",
      category: "Fashion",
      imageSrc: Hermes,
      hash: "LFFGq100@*0^HZ9YOm~W[e9syqvi",
      loaded: false,
      image: hermesBanner,
      bullets: ["Controlled 7-figure annual paid media budget.", "Leveraged proprietary targeting technology, allocating 21% of total paid media investment.", "Improved cost per awareness and conversion-based metrics by 12% through proprietary technology.", "Conducted effective A/B testing for optimal frequency, brand awareness, and KPI alignment.","Owned end-to-end campaign strategy, execution, pacing, and client meetings."]
      
    },
    {
      company: "Red Ventures",
      category: "Digital Marketing",
      imageSrc: RV,
      hash: "L197eL4n00D%00fQIU%May_3-;Rj",
      loaded: false,
      image: rvBanner,
      bullets: ["Managed 650+ branded content campaigns for clients such as CNET, ZDNet, GameSpot, and TechRepublic across multiple global locations.", "Developed dynamic pacing reports to meet primary and secondary KPIs.", "Collaborated on targeting strategies with clients including Walmart, Microsoft, PayPal, IBM, PUBG, WarFrame, and KitchenAid.", "Assisted in creating KPI rate cards for improved service delivery and efficiency at Red Ventures."]
      
    },
    {
      company: "Joolaree",
      category: "Jewelry",
      imageSrc: Joolaree,
      hash: "LLEB$%~UxZs-r?a{s.WBM|jFR*t6",
      loaded: false,
      image: joolareeBanner,
      bullets: ["Achieved a 400% revenue increase with a consistent 4x ROAS.", "Conducted A/B testing on multiple landing pages to optimize conversion rates.", "Streamlined ad creation by editing raw videos for rapid testing and deployment."]
      
    },
    {
      company: "KingCredit",
      category: "Credit",
      imageSrc: kingCredit,
      hash: "LDDSE[pJEgIV~ns+RPNGP.%MrrIU",
      loaded: false,
      image: kingCreditBanner,
      bullets: ["Demonstrated the effectiveness of our growth strategies by achieving a >75% increase in social media following within a 10-month period.","Utilized paid native placements on prominent social platforms such as Instagram and TikTok, leveraging these platforms for rapid audience expansion.","Built and leveraged the website in driving traffic and audience identification, showcasing its significance as a valuable tool.", "Engaged in collaborative partnerships with fellow influencers, effectively illustrating the mutual benefits of such collaborations. These partnerships not only amplified brand awareness but also significantly contributed to increased sales opportunities."]
      
    },
    {
      company: "Tutelaps",
      category: "Plastic Surgery",
      imageSrc: Tutela,
      hash: "LJM%v40LS#NF~SkXSi9FEK%MnOWT",
      loaded: false,
      image: tutelaBanner,
      bullets: ["Successfully managed paid advertising campaigns to enhance brand image and increased engagement by 151%.","Effectively communicated COVID-19 office restrictions to custom audiences through targeted ads.","Increased call volume utilizing proven strategies and implemented various methods to filter and qualify prospects.", "Maintained a focus on Lifetime Value (LTV) when acquiring new clients", "Demonstrated versatility in adapting to changing circumstances during the pandemic and the iOS14.5 update."]
      
    },
    {
      company: "jesusortizpazfr",
      category: "Art & Music",
      imageSrc: Ortiz,
      hash: "LMB}2l%#.TVrMwt7RjxuDiIUMxW=",
      loaded: false,
      image: jortizBanner,
      bullets: ["Spearheaded a dedicated team of professionals to successfully conceptualize and execute the development of an artist's e-commerce merchandise website.","Surpassed projections and benchmarks by generating over $10,000 per day in sales.","Strategically orchestrated and managed paid advertising campaigns to propel the initial launch of the e-commerce merch site, ensuring a high level of visibility and engagement.","Demonstrated strong leadership and project management skills, driving the project to fruition and delivering tangible financial results for the artist."]
      
    }
  ];

  export default casesData