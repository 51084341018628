import React from "react";
import "../../styles/MobileOverlay.css";
import { Link } from "react-router-dom";
const MobileMenuOverlay = ({ isMobileMenuOpen, handleClick, handleModalClick }) => {
  return (
    <div
      className={`mobile-menu-overlay ${isMobileMenuOpen ? "show" : "hide"}`}
    >
      <Link to="/">
        <p onClick={() => handleClick()}>Home</p>
      </Link>
      <Link to="/results">
        <p onClick={() => handleClick()}>Results</p>
      </Link>
      <p>About</p>
      <p>Services</p>
      <p>Blog</p>
      <p onClick={handleModalClick}>Contact</p>
    </div>
  );
};

export default MobileMenuOverlay;
