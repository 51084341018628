import React, { useEffect, useState } from "react";
import "../../styles/Nav.css";
import gotads from "../../assets/main-assets/gotads.png";
import { MdOutlineToggleOff, MdOutlineToggleOn } from "react-icons/md";
import { Link } from "react-router-dom";

const Nav = ({ isToggleOn, handleToggle, isMobileMenuOpen, handleClick, handleModalClick }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      if (scrollTop > 0 && !isScrolled) {
        setIsScrolled(true);
      } else if (scrollTop === 0 && isScrolled) {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrolled]);

  const navbarClasses = `advertiser-nav ${isScrolled ? "scrolled" : ""}`;

  return (
    <nav className={navbarClasses}>
      <div className="nav-wrapper">
        <Link to="/">
          <div className="left-side">
            <img src={gotads} alt="logo" loading="lazy" />
            <h3 className="logo">Got Ads</h3>
          </div>
        </Link>
        <div
          className={`nav-elements ${
            isMobileMenuOpen ? "mobile-menu-open" : ""
          }`}
        >
          <Link to="/results">
            <p>Results</p>
          </Link>
          <p>About</p>
          <p>Services</p>
          <p>Blog</p>
          <p onClick={handleModalClick}>Contact</p>
        </div>
        {/* <div className="right-side">
          {isToggleOn ? (
            <MdOutlineToggleOn id="toggle" onClick={handleToggle} />
          ) : (
            <MdOutlineToggleOff id="toggle" onClick={handleToggle} />
          )}
          <p id="sign-in">Log In as Advertiser</p>
        </div> */}
        {/* Mobile Button */}
        <button
          className="nav__burger"
          onClick={handleClick}
          aria-label="Nav Burger"
        >
          <svg width="30" height="30" viewBox="0 0 100 100">
            <path
              className="line line1"
              d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
            />
            <path className="line line2" d="M 20,50 H 80" />
            <path
              className="line line3"
              d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
            />
          </svg>
        </button>
        {/* Mobile Button */}
      </div>
    </nav>
  );
};

export default Nav;
