import React, { useEffect, useState } from 'react';
import gotads from '../../assets/main-assets/gotads.png';
import { MdOutlineToggleOff, MdOutlineToggleOn } from 'react-icons/md';
const CNav = ({handleToggle, isToggleOn}) => {
    const [isScrolled, setIsScrolled] = useState(false);
  
 

    useEffect(() => {
      const handleScroll = () => {
        const scrollTop = window.pageYOffset;
  
        if (scrollTop > 0 && !isScrolled) {
          setIsScrolled(true);
        } else if (scrollTop === 0 && isScrolled) {
          setIsScrolled(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [isScrolled]);
  
    const navbarClasses = `brand-nav ${isScrolled ? 'scrolled' : ''}`;
  
    
  
    return (
      <nav className={navbarClasses}>
        <div className="nav-wrapper brand-nav-wrapper">
          <div className="left-side">
            <img src={gotads} alt="" loading="lazy"/>
            <h3 className="font-bold text-xl m-6 tracking-widest">GOTADS.COM</h3>
            <div className="nav-elements">
              <p id="first">Publishers</p>
              <p>Brands</p>
              <p>About</p>
            </div>
          </div>
          <div className="right-side">
            {isToggleOn ? (
              <MdOutlineToggleOn id="toggle" onClick={handleToggle} />
            ) : (
              <MdOutlineToggleOff id="toggle" onClick={handleToggle} />
            )}
            <p id="sign-in">Log In as Brand</p>
          </div>
        </div>
        
      </nav>
    );
  };


export default CNav;
