import React from "react";
import { FaCheck } from "react-icons/fa";

const Earned = ({handleModalClick}) => {
  return (
    <div className="solution__box earned">
      <h2 className="underlined-text">Earned Media</h2>
      <ul className="list-style-arrow">
        <li>
        <FaCheck id="check" /> 
          <a>
         Search Engine Optimization (SEO)
          </a>
        </li>
        <li>
        <FaCheck id="check" /> 
          <a>
      Conversion Rate Optimization (CRO)
          </a>
        </li>
        <li>
        <FaCheck id="check" /> 
          <a >
            Social Media Management
          </a>
        </li>
        <li>
        <FaCheck id="check" /> 
          <a >
            SMS Marketing
          </a>
        </li>
        <li>
        <FaCheck id="check" /> 
          <a >
            Marketing Automation
          </a>
        </li>
        <li>
        <FaCheck id="check" /> 
          <a>
            Content Marketing
          </a>
        </li>
      </ul>
      <button onClick={handleModalClick}>Learn More</button>
    </div>
  );
};

export default Earned;
