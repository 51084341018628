import React, { useState } from "react";
import "../../../styles/Pages/Home/Companies.css";
import logos from "../../Data/Logos/Logos";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Companies = () => {
  const [duplicatedLogos, setDuplicatedLogos] = useState([...logos]);

  return (
    <div className="Companies">
      <div className="companies-wrapper">
        <p id="clients">Our Clients</p>
        <div className="carousel-container">
          <Carousel
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            autoPlay={true}
            infiniteLoop={true}
            transitionTime={1500}
            interval={2000}
            slidesToShow={6}
            slidesToScroll={6}
            centerMode={true}
            centerSlidePercentage={100 / 6}
            className="slider"
            showIndicators={false}
            onChange={(index) => {
              // Check if we have reached the end of the current logos set
              if (index === duplicatedLogos.length - 4) {
                // Append another set of logos to the array
                setDuplicatedLogos((prevLogos) => {
                  const newLogos = [...prevLogos, ...logos];
                  // Keep only the most recent logos
                  return newLogos.slice(-100); // Keep the last 100 logos
                });
              }
            }}
          >
            {duplicatedLogos.map((logo, index) => (
              <div key={index} className="company">
                <img src={logo} alt={index} loading="lazy"/>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Companies;
