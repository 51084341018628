import React, { useState } from "react";
import ReactModal from "react-modal";
import "../../../styles/FormModal.css";
import { RxCross2 } from "react-icons/rx";
import ReCAPTCHA from "react-google-recaptcha";

const FormModal = ({ step, setStep, isModalOpen, setIsModalOpen }) => {
    const totalSteps = 2; // Total number of steps

    // Calculate the width percentage for the progress bar
    const progressBarWidth = `${(step / totalSteps) * 100}%`;

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        message: "",
        phone: "",
        recaptcha: "",
        company: "",
        position: ""
    });

    const [errors, setErrors] = useState({
        firstName: "First name is required",
        lastName: "Last name is required",
        email: "Email is required",
        message: "Message is required",
        phone: "Phone number is required",
        recaptcha: "",
        company: "Company is required",
        position: "Position is required"
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleNext = (e) => {
        e.preventDefault();

        let newErrors = {};

        // Basic validation for required fields
        if (formData.firstName.trim() === "") {
            newErrors.firstName = "First name is required";
        }
        if (formData.lastName.trim() === "") {
            newErrors.lastName = "Last name is required";
        }

        if (formData.email.trim() === "") {
            newErrors.email = "Email is required";
        } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
            newErrors.email = "Invalid email format";
        }
        if (formData.phone.trim() === "") {
            newErrors.phone = "Phone number is required";
        } else if (!/^\d{10}$/.test(formData.phone)) {
            newErrors.phone = "Invalid phone number";
        }

        // Set errors if any
        setErrors(newErrors);

        // If there are no errors, proceed to next step
        if (Object.keys(newErrors).length === 0) {
            setStep(step + 1); // Move to next step
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
    
        let newErrors = {};
    
        if (formData.message.trim() === "") {
            newErrors.message = "Message is required";
        }
        if (formData.company.trim() === "") {
            newErrors.company = "Company is required";
        }
        if (formData.position.trim() === "") {
            newErrors.position = "Position is required";
        }
    
        // Set errors if any
        setErrors(newErrors);
    
        // If there are no errors, submit the form
        if (Object.keys(newErrors).length === 0) {

              // Exclude 'recaptcha' from formData
              const { recaptcha, ...formDataWithoutRecaptcha } = formData;
            try {
                // Make a POST request to SheetMonkey
                const response = await fetch(`https://api.sheetmonkey.io/form/${process.env.REACT_APP_API_KEY}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formDataWithoutRecaptcha),
                });
    
                if (response.ok) {
                    alert("Form submitted successfully!");
                    setIsModalOpen(false); // Close the modal after submission
                } else {
                    throw new Error('Failed to submit form');
                }
            } catch (error) {
                console.error('Error submitting form:', error);
                // Handle error (e.g., display an error message to the user)
            }
        }
    };
    


    return (
        <ReactModal className="modal"
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            overlayClassName="modal-overlay"
        >
            <form onSubmit={handleSubmit}>
                <RxCross2 id="exit-btn" onClick={() => setIsModalOpen(false)} />
                <h1>Get In Touch</h1>
                {step === 1 && (
                    <>
                        <p>Step 1: Personal Information</p>
                        <div className="form-group">
                            <h4>First Name <span id="asterisk">*</span></h4>
                            <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} />
                            {errors.firstName && <span className="error">{errors.firstName}</span>}
                        </div>
                        <div className="form-group">
                            <h4>Last Name <span id="asterisk">*</span></h4>
                            <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} />
                            {errors.lastName && <span className="error">{errors.lastName}</span>}
                        </div>
                        <div className="form-group">
                            <h4>Email <span id="asterisk">*</span></h4>
                            <input type="text" name="email" value={formData.email} onChange={handleChange} />
                            {errors.email && <span className="error">{errors.email}</span>}
                        </div>
                        <div className="form-group">
                            <h4>Phone Number <span id="asterisk">*</span></h4>
                            <input type="text" name="phone" value={formData.phone} onChange={handleChange} />
                            {errors.phone && <span className="error">{errors.phone}</span>}
                        </div>

                    </>
                )}
                {step === 2 && (
                    <>
                        <p>Step 2: Message</p>
                        <div className="form-group group-2">
                            <h4>Company <span id="asterisk">*</span></h4>
                            <input type="text" name="company" value={formData.company} onChange={handleChange} />
                            {errors.company && <span className="error">{errors.company}</span>}
                        </div>
                        <div className="form-group group-2">
                            <h4>Position <span id="asterisk">*</span></h4>
                            <input type="text" name="position" value={formData.position} onChange={handleChange} />
                            {errors.position && <span className="error">{errors.position}</span>}
                        </div>
                        <div className="form-group group-2">
                            <h4>Message <span id="asterisk">*</span></h4>
                            <textarea name="message" value={formData.message} onChange={handleChange} cols="30" rows="10"></textarea>
                            {errors.message && <span className="error">{errors.message}</span>}
                        </div>

                        <div className="form-group group-2">
                            <ReCAPTCHA
                                sitekey="6LegH7QpAAAAAMRc0vqMa1N2wKDWSTPh-YuEJkB-"
                                className="captcha"

                                onChange={(value) => setFormData({ ...formData, recaptcha: value })} // Handle onChange event
                            />
                            {errors.recaptcha && <span className="error">{errors.recaptcha}</span>}
                        </div>


                    </>
                )}

                <button type="submit" onClick={step === 1 ? handleNext : handleSubmit}>{step === 1 ? "Next" : "Submit"}</button>
                <div className={`progress-bar ${isModalOpen ? "progress-bar-visible" : ""} ${step === 2 ? "progress-bar-next" : ""}`} style={{ width: progressBarWidth }}></div>



            </form>
        </ReactModal>
    );
};

export default FormModal;
