import "./App.css";
import { useEffect, useState } from "react";
// import load from "./assets/main-assets/loading-1.gif";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// --- EVERY PAGE ---
import Nav from "./components/Advertiser Side/Nav";
import Footer from "./components/Advertiser Side/Footer.jsx";
import CNav from "./components/Brand Side/C-Nav.jsx";
import CMain from "./components/Brand Side/C-Main.jsx";
// --- EVERY PAGE ---

// --- HOME ---
import Main from "./components/Pages/Home/Main";
import Companies from "./components/Pages/Home/Companies";
import Overview from "./components/Pages/Home/How it works/Overview.jsx";
import LearnMore from "./components/Pages/Home/How it works/LearnMore.jsx";
import Chat from "./components/Pages/Home/Lets Chat/Chat.jsx";
import Technologies from "./components/Pages/Home/How it works/Technologies.jsx";
import Clients from "./components/Pages/Home/Clients.jsx";
import Testimonials from "./components/Pages/Home/Testimonials/Testimonials.jsx";
// --- HOME ---

// --- CASE STUDIES ---
import CaseMain from "./components/Pages/Case Studies/CaseMain.jsx";
import Results from "./components/Pages/Case Studies/Results.jsx";
import Chat2 from "./components/Pages/Home/Lets Chat/Chat2.jsx";
import MobileMenuOverlay from "./components/Advertiser Side/MobileOverlay.jsx";
import ScrollToTop from "./components/Data/ScrollToTop/ScrollToTop.jsx";
import Result from "./components/Pages/Case Studies/Case Study/Result.jsx";
import FormModal from "./components/Pages/Modals/FormModal.jsx";
// --- CASE STUDIES ---

function App() {
  const [isToggleOn, setIsToggleOn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [step, setStep] = useState(1); // State to manage form steps
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);
  // const handleToggle = () => {
  //   setIsLoading(true);
  //   setIsToggleOn(!isToggleOn);

  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 3000);
  // };
  const handleClick = () => {
    const button = document.querySelector(".nav__burger");
    button.classList.toggle("opened");
    button.setAttribute("aria-expanded", button.classList.contains("opened"));
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleModalClick = () => {
    setStep(1)
    setIsModalOpen(true)
  }

  return (
    <div className="App">
      {/* {isLoading ? (
        <div
          className={`loading-overlay ${
            isToggleOn || !isToggleOn ? "show" : "hide"
          }`}
        >
          <img src={load} alt="Loading" loading="lazy"/>
        </div>
      ) : (
        <div className={`loading-overlay hide`}>
          <img src={load} alt="Loading" loading="lazy"/>
        </div>
      )} */}
      <BrowserRouter>
        <ScrollToTop>
          <FormModal step={step} setStep={setStep} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
          <Nav
            isToggleOn={isToggleOn}
            setIsToggleOn={setIsToggleOn}
            // handleToggle={handleToggle}
            isMobileMenuOpen={isMobileMenuOpen}
            setMobileMenuOpen={setMobileMenuOpen}
            handleClick={handleClick}
            handleModalClick={handleModalClick}
          />
          <MobileMenuOverlay
            isMobileMenuOpen={isMobileMenuOpen}
            handleClick={handleClick}
            handleModalClick={handleModalClick}
          />
          <Routes>
            <Route
              path="/"
              element={
                <div
                  className={`background-content ${isLoading ? "hide" : ""}`}
                >
                  {!isToggleOn && (
                    <>
                      <Main handleModalClick={handleModalClick}/>
                      <Companies />
                      <Overview />
                      <LearnMore handleModalClick={handleModalClick}/>
                      <Chat handleModalClick={handleModalClick}/>
                      <Technologies />
                      <Chat2 handleModalClick={handleModalClick}/>
                      <Clients />
                      <Testimonials />
                      <Footer />
                    </>
                  )}
                  {isToggleOn && (
                    <>
                      <CNav
                        isToggleOn={isToggleOn}
                        setIsToggleOn={setIsToggleOn}
                        // handleToggle={handleToggle}
                      />
                      <CMain />
                      <Companies />
                      <Footer />
                    </>
                  )}
                </div>
              }
            />
            <Route
              path="/results"
              element={
                <div
                  className={`background-content ${isLoading ? "hide" : ""}`}
                >
                  {!isToggleOn && (
                    <>
                      <CaseMain />
                      <Results/>
                      <Footer />
                    </>
                  )}
                  {isToggleOn && (
                    <>
                      <CNav
                        isToggleOn={isToggleOn}
                        setIsToggleOn={setIsToggleOn}
                        // handleToggle={handleToggle}
                      />
                      <CMain />
                      <Companies />
                      <Footer />
                    </>
                  )}
                </div>
              }
            />
            <Route
              path="/results/:companyName"
              element={
                <>
                
                  <Result />
                  <Footer />
                </>
              }
            />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
}

export default App;
