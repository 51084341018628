import React from "react";
import "../../styles/Footer.css";
import gotads from "../../assets/main-assets/gotads.png";
import { FaFacebookF } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { FaSquareXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="Footer">
      <div className="infos">
        <div className="info__left-side">
          <p className="email">info@gotads.com</p>
          <p>•</p>
          <p>+1 202 555 0185</p>
        </div>
        <div className="info__right-side">
          <Link to="/">
            <p>HOME</p>
          </Link>
          <Link to="/results">
            <p>RESULTS</p>
          </Link>
          <p>ABOUT</p>
          <p>SERVICES</p>
          <p>BLOG</p>
          <p>CONTACT</p>
        </div>
      </div>
      <div className="footer__infos">
        <div className="footer__left-side">
          <div className="footer__left-wrapper">
            <div className="footer__logo">
              <img src={gotads} alt="logo" />
              <h3 className="logo">Got Ads?</h3>
            </div>
            <h4>Your Strategic Partner in Paid Media Investments</h4>
            <div className="copyright">
              {" "}
              Copyright © GotAds 2024. All rights reserved.
            </div>
          </div>
        </div>
        <div className="footer__right-side">
          <div className="newsletter">
            <input type="text" placeholder="Sign Up To Our Newsletter!" />
            <button>Submit</button>
          </div>
          <div className="footer__socials">
            <FaFacebookF className="social" />
            <AiFillInstagram className="social" />
            <FaSquareXTwitter className="social" />
          </div>

          <p>Privacy Policy</p>
          <div className="copyright copyright__mobile">
            {" "}
            Copyright © GotAds 2024. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
