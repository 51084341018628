import React, { useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import "../../../../styles/Pages/Home/LearnMore.css";
import Earned from "./Solution Boxes/Earned";
import Paid from "./Solution Boxes/Paid";
import ECommerce from "./Solution Boxes/ECommerce";

const LearnMore = ({handleModalClick}) => {
  const controls = useAnimation();
  const mainRef = useRef(); // Main container ref
  const boxesRef = useRef(); // Ref for solution__boxes container

  useEffect(() => {
    const mainElement = mainRef.current;
    const underlines = boxesRef.current.querySelectorAll(".underlined-text");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            controls.start({ y: 0, opacity: 1 });
            underlines.forEach((underline) => {
              underline.classList.add("is-visible");
            });
          } else {
            controls.start({ y: -50, opacity: 0 });
            underlines.forEach((underline) => {
              underline.classList.remove("is-visible");
            });
          }
        });
      },
      { threshold: 0.1 }
    );

    if (mainElement) {
      observer.observe(mainElement);
    }

    return () => {
      if (mainElement) {
        observer.unobserve(mainElement);
      }
    };
  }, [controls]);

  return (
    <div className="LearnMore" ref={mainRef}>
      <motion.div
        initial={{ y: -50, opacity: 0 }}
        animate={controls}
        transition={{ duration: 1 }}
      >
        <h1>Driving Digital ROI</h1>
        <h1>With OmniChannel Solutions</h1>
        <div className="learn__texts">
          <p>Your customers interact everywhere—so your</p>
          <p>strategies need to be agile and actionable.</p>
        </div>

        <div className="solution__boxes" ref={boxesRef}>
          <Earned handleModalClick={handleModalClick}/>
          <Paid handleModalClick={handleModalClick}/>
          <ECommerce handleModalClick={handleModalClick}/>
        </div>
      </motion.div>
    </div>
  );
};

export default LearnMore;
