import React, { useState, useEffect } from "react";
import "../../../styles/Pages/Case Studies/CaseMain.css";
import picture from "../../../assets/main-assets/case-studies.jpg";
import { Blurhash } from "react-blurhash";

const CaseMain = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = picture;

    img.onload = () => {
      setImageLoaded(true);
    };
  }, []);

  return (
    <div className="CaseMain">
      {!imageLoaded ? (
        <Blurhash
          hash="LFEVWz=|RNRj~XaJnNR*9ZD*-;NG"
          width="100%"
          height={700}
          resolutionX={128}
          resolutionY={128}
          punch={1}
        />
      ) : (
        <img
          src={picture}
          alt="case-study"
          loading="lazy"
          style={{ display: imageLoaded ? "flex" : "none" }}
        />
      )}
      <div className="results-overlay"/>
      <h1>Results</h1>
    </div>
  );
};

export default CaseMain;
