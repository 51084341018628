import React, { useEffect, useRef } from 'react'
import "../../../../styles/Pages/Home/Chat.css"
import chat from "../../../../assets/main-assets/chat2.mp4"
const Chat2 = ({handleModalClick}) => {
    const videoRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const videoPosition = videoRef.current.getBoundingClientRect();

      if (videoPosition.top < window.innerHeight && videoPosition.bottom >= 0) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className='other__chat'>
        <video src={chat} ref={videoRef} autoPlay muted  preload="metadata" playsInline></video>
        <h1>Unlock Your Brand's Profit Potential</h1>
        <button onClick={handleModalClick}><span>+</span> Let's Chat</button>
        </div>
  )
}

export default Chat2