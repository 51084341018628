import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import "../../../../styles/Pages/Home/Overview.css";
import { Link } from "react-router-dom";

const Overview = () => {
  const controls = useAnimation();
  const ref = useRef();
  const [boxOne, setBoxOne] = useState(0);
  const [boxTwo, setBoxTwo] = useState(0);
  const [boxThree, setBoxThree] = useState(0);
  const [boxFour, setBoxFour] = useState(0);

  useEffect(() => {
    const element = ref.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            controls.start({ y: 0, opacity: 1 });
            animateNumber(0, 75, 2000, setBoxOne);
            animateNumber(0, 400, 2000, setBoxTwo);
            animateNumber(0, 650, 2000, setBoxThree);
            animateNumber(0, 12, 2000, setBoxFour);
          } else {
            controls.start({ y: -50, opacity: 0 });
          }
        });
      },
      { threshold: 0.1 }
    );

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [controls]);

  const animateNumber = (start, end, duration, setFunction) => {
    const startTime = new Date().getTime();
    const updateNumber = () => {
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - startTime;

      if (elapsedTime < duration) {
        const newValue = Math.floor(
          start + ((end - start) * elapsedTime) / duration
        );
        setFunction(newValue);
        requestAnimationFrame(updateNumber);
      } else {
        setFunction(end);
      }
    };

    updateNumber();
  };

  return (
    <div className="overview__wrapper">
      <motion.div
        className="overview"
        ref={ref}
        initial={{ y: -50, opacity: 0 }}
        animate={controls}
        transition={{ duration: 1 }}
      >
        <div className="boxes">
          <Link to="/results/Epson">
            <div className="box box-1">
              <div className="box-1-color">
                +{boxOne}%<p>Enhanced media diversification for effective campaigns.</p>
              </div>
            </div>
          </Link>
          <Link to="/results/Joolaree">
            <div className="box box-2">
              <div className="box-2-color">
                +{boxTwo}% <p>Revenue increase with a consistent 4x ROAS.</p>
              </div>
            </div>
          </Link>
          <Link to="/results/Red Ventures">
            <div className="box box-3">
              <div className="box-3-color">
                {boxThree}+
                <p>Campaigns for CNET, ZDNet, GameSpot, TechRepublic.</p>
              </div>
            </div>
          </Link>
          <Link to="/results/Hermes">
            <div className="box box-4">
              <div className="box-4-color">
                +{boxFour}%
                <p>Improved cost per awareness and conversion-based metrics.</p>
              </div>
            </div>
          </Link>
        </div>

        <div className="overview__texts">
          <h1>Digital Solutions That Work</h1>
          <p>
            Our omnichannel digital marketing strategies are changing the game
            for ecommerce ROI and digital marketplace profitability.
          </p>
          <Link to="/results">
            <button>See Our Results</button>
          </Link>
        </div>
      </motion.div>
    </div>
  );
};

export default Overview;
