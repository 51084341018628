import React, { useEffect, useRef } from "react";
import "../../../styles/Pages/Home/Clients.css";
import { motion, useAnimation } from "framer-motion";
import logos from "../../Data/Logos/Logos";
const Clients = () => {
  const controls = useAnimation();
  const ref = useRef();

  useEffect(() => {
    const element = ref.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            controls.start({ y: 0, opacity: 1 });
          } else {
            controls.start({ y: -50, opacity: 0 });
          }
        });
      },
      { threshold: 0.1 }
    );

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [controls]);
  return (
    <div className="Clients">
      <motion.div
        ref={ref}
        initial={{ y: -50, opacity: 0 }}
        animate={controls}
        transition={{ duration: 1 }}
        className="clients__content"
      >
        <h1><span>Success </span> Comes in Many Forms</h1>
        <p>And so do our clients</p>
        <div className="client__logos">
          {logos.map((logo, index) => (
            <div key={index} className="client">
              <img src={logo} alt={index} loading="lazy"/>
            </div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default Clients;
