import React from 'react';
import man from "../../assets/main-assets/man-vector.png"
import youtube from "../../assets/social media logos/youtube.svg"
import facebook from "../../assets/social media logos/facebook.svg"
import tiktok from "../../assets/social media logos/tiktok.svg"
import instagram from "../../assets/social media logos/instagram.svg"
import twitter from "../../assets/social media logos/twitter.svg"
const CMain = () => {
    return (
        <div className='Main brand-main'>
            <img src={youtube} className="socials youtube"alt="" loading="lazy"/>
            <img src={facebook} className="socials facebook"alt="" loading="lazy"/>
            <img src={tiktok} className="socials tiktok"alt="" loading="lazy"/>
            <img src={twitter} className="socials twitter"alt="" loading="lazy"/>
            <img src={instagram} className="socials instagram"alt="" loading="lazy"/>
            <div className="main-content flex items-center justify-start relative mb-12">
                
                <div className='z-2999'>

                    <h1 >
                        <span>CONTENT</span>
                        <span>CREATOR</span>
                        <span>SIDE.</span>



                    </h1>
                    <button className="rounded-full mt-12 text-m font-medium right-0 h-14 w-5/6 px-2 text-white focus:outline-none hover:bg-blue-600 transition-colors duration-300">
                        Get Started
                    </button>


                </div>
                <img src={man} alt=""  />

            </div>



        </div>
    );

}

export default CMain;
