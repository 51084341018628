import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import "../../../../styles/Pages/Home/Testimonials.css";
import { IoMdQuote } from "react-icons/io";
import { IoStarSharp } from "react-icons/io5";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
// PROFILES
import Luiza from "../../../../assets/profiles/Luiza.jpeg"
import Oleksiy from "../../../../assets/profiles/Oleksiy.jpeg"
import Vera from "../../../../assets/profiles/Vera.jpeg"
import Vasyl from "../../../../assets/profiles/Vasyl.jpeg"


const TestimonialSlide = ({
  text,
  author,
  starsArray,
  handleNextSlide,
  handlePrevSlide,
  img
}) => {
  const controls = useAnimation();
  const divRef = useRef();
  const quoteRef = useRef();

  useEffect(() => {
    const element = divRef.current;
    const quoteDiv = quoteRef.current.querySelector(".quote__div");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            controls.start({ opacity: 1, y: 0 });
            quoteDiv.classList.add("is-visible");
          } else {
            controls.start({ y: -50 });
            quoteDiv.classList.remove("is-visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [controls]);
  

  return (
    <motion.div
      className="testimonial__content"
      ref={divRef}
      initial={{ y: -50, opacity: 0 }}
      animate={controls}
      transition={{duration: 1}}

    >
      <motion.div
        id="arrow-left"
        onClick={() => handlePrevSlide()}
        whileHover={{
          scale: 0.9,
          transition: { duration: 0.3, ease: "linear" },
        }}
        whileTap={{ opacity: 0.5 }}
      >
        <FaLongArrowAltLeft />
      </motion.div>
      <motion.div
        id="arrow-right"
        onClick={() => handleNextSlide()}
        whileHover={{
          scale: 0.9,
          transition: { duration: 0.3, ease: "linear" },
        }}
        whileTap={{ opacity: 0.5 }}
      >
        <FaLongArrowAltRight />
      </motion.div>
      <div className="testimonial__profile" ref={quoteRef}>
        <div className="quote__div">
          <IoMdQuote id="quote" />
        </div>
        <img id="profile" src={img} alt="profile"/>
      </div>
      <blockquote className="testimonial__texts">
        <div className="stars">{starsArray}</div>

        <p>{text}</p>
        <cite>{author}</cite>
      </blockquote>
    </motion.div>
  );
};

const Testimonials = () => {
  
  const numberOfStars = 5;
  const starsArray = Array.from({ length: numberOfStars }, (_, index) => (
    <IoStarSharp key={index} className="star" />
  ));
  const preloadImages = () => {
    testimonials.forEach(testimonial => {
      const img = new Image();
      img.src = testimonial.img;
    });
  };
  
  useEffect(() => {
    preloadImages();
  }, []);

  const testimonials = [
    {
      text: "GotAds team are great specialists in Social Media Marketing. We worked together on the promotion of our business in the US market. I would definitely recommend working with them!",
      author: "Vasyl D.",
      img: Vasyl
    },
    {
      text: "Vaughn and his team are very bright dedicated, motivated young professionals. I worked with them on several client's advertising accounts (paid social media) at National Positions, what implied an added skill to navigate between different e-commerce scenarios and marketing strategies. Their ability to handle multiple clients and projects at once was really impressive, as well as their dedication to deal with tough clients and keep a healthy relationship with them by being highly responsive and attentive to their professional needs. What really impressed me about the team was their eagerness to learn, as they always went the extra mile to do their research in regards to any topic.",
      author: "Luiza K.",
      img: Luiza
    },
    {
      text: "Vaughn and his team helped us launch sales from scratch, tapping into his experience and knowledge of digital ad campaigns, with a focus on Facebook and Instagram ads. On top of that, they also contributed with marketing communications and PR. Vaughn comes up with the most useful consumer insights, which helped us improve our marketing strategy further! He is an adept communicator, digital marketer, and is always on a look out for alternative business solutions. 2 facts about Vaughn that I’ve learned: He has a great sense of humor and he would never miss a pizza party!",
      author: "Alex M.",
      img: Oleksiy
    },
    {
      text: "I’ve had a lot of pleasure working with Vaughn and his team on Rover Tags, Animal ID’s affiliate product. I was impressed with the team’s knowledge of paid media campaign analytics as well as their strategic thinking. They have tirelessly tested their creative content and messaging ideas. And what is most important, the team makes decisions based on actual data that bring results. They have been great team players, very nice people to work with, and comes with my heartfelt recommendation.",
      author: "Vera P.",
      img: Vera
    },
  ];
 

  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

  const handleNextSlide = () => {
    setCurrentTestimonialIndex((prevIndex) =>
      prevIndex < testimonials.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handlePrevSlide = () => {
    setCurrentTestimonialIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : testimonials.length - 1
    );
  };

  return (
    <div className="Testimonials">
      <TestimonialSlide
        text={testimonials[currentTestimonialIndex].text}
        author={testimonials[currentTestimonialIndex].author}
        starsArray={starsArray}
        handleNextSlide={handleNextSlide}
        handlePrevSlide={handlePrevSlide}
        img={testimonials[currentTestimonialIndex].img}
      />
    </div>
  );
};

export default Testimonials;
