import React from "react";
import "../../../../styles/Pages/Home/Chat.css";
const Chat = ({handleModalClick}) => {
  return (
    <div className="Chat">
     
      <h1>
        Discover What Success Really Looks Like. 
      </h1>
      <button onClick={handleModalClick}>Let's Chat</button>
    </div>
  );
};

export default Chat;
