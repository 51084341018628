import React from "react";
import "../../../../styles/Pages/Case Studies/Case Study/Result.css";
import casesData from "../../../Data/Case Studies/CaseStudies";

const Result = () => {
  const fullURL = window.location.pathname;

  // Split the URL by '/'
  const urlParts = fullURL.split("/");

  // Get the last part of the array (which is "Epson")
  const desiredPart = urlParts[urlParts.length - 1];

  // Remove texts with percent from URL
  const decodedURL = decodeURIComponent(desiredPart);
  // Create the new URL ("/" + "Epson")

  const result = decodedURL;
  const caseStudy = casesData.filter((study) => study.company === result);

  return (
    <div className="Result">

      <div className="result__banner">
        <div className="result-overlay" />
        <div className="result-texts">
          <h1>{caseStudy[0].company}</h1>
          <p>{caseStudy[0].category}</p>
        </div>

        <img src={caseStudy[0].image} alt={caseStudy[0].company} loading="lazy" />

      </div>
      <div className="bullet__points">
        {caseStudy[0].bullets.map((bullet, index) => (
          <p key={index}><span className="bullet">•</span> {bullet}</p>
        ))}
      </div>
    </div>
  );
};

export default Result;
