import React, { useEffect, useState } from "react";
import "../../../styles/Pages/Case Studies/Results.css";
import { Link } from "react-router-dom";
import casesData from "../../Data/Case Studies/CaseStudies";
import { Blurhash } from "react-blurhash";

const Results = () => {
  const [imageLoaded, setImageLoaded] = useState(
    JSON.parse(localStorage.getItem("imageLoaded")) || {}
  );

  useEffect(() => {
    casesData.forEach((caseItem) => {
      const img = new Image();
      img.src = caseItem.imageSrc;

      img.onload = () => {
        setImageLoaded((prevImageLoaded) => ({
          ...prevImageLoaded,
          [caseItem.loaded]: true,
        }));

        localStorage.setItem("imageLoaded", JSON.stringify(imageLoaded));
      };

      img.onerror = (e) => {
        console.error("Error loading image:", e);
      };
    });
  }, []);

  return (
    <div className="Results">
     
      <div className="cases">
        {casesData.map((caseItem, index) => (
          <div key={index} className="case">
            <div className="case-content">
              {!imageLoaded[caseItem.loaded] ? (
                <Blurhash
                  hash={caseItem.hash}
                  width={400}
                  height={500}
                  resolutionX={32}
                  resolutionY={32}
                  punch={1}
                />
              ) : (
                <img
                  src={caseItem.imageSrc}
                  alt={index}
                  loading="lazy"
                />
              )}
              <div className="overlay">
                <h1>{caseItem.company}</h1>
                <h2>{caseItem.category}</h2>
                <Link to={`/results/${caseItem.company}`}>
                  <button>Learn More</button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Results;
