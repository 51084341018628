import React from "react";
import { FaCheck } from "react-icons/fa";
const ECommerce = ({handleModalClick}) => {
  return (
    <div className="solution__box e-commerce">
      <h2 className="underlined-text">E-Commerce Solutions</h2>
      <ul className="list-style-arrow">
        <li>
        <FaCheck id="check" /> 
          <a >
            Complete Amazon Management{" "}
          </a>
        </li>
        <li>
        <FaCheck id="check" /> 
          <a>
            Google Shopping Management{" "}
          </a>
        </li>
        <li>
        <FaCheck id="check" /> 
          <a >
            Product Feed Development{" "}
          </a>
        </li>
        <li>
        <FaCheck id="check" /> 
          <a >
            Social Commerce Integration{" "}
          </a>
        </li>
        <li>
        <FaCheck id="check" /> 
          <a>
            Shopify Experts{" "}
          </a>
        </li>
        <li>
        <FaCheck id="check" /> 
          <a>
            Ecommerce UX Optimization
          </a>
        </li>
      </ul>
      <button onClick={handleModalClick}>Learn More</button>
    </div>
  );
};

export default ECommerce;
