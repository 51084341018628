import React from "react";
import { IoStatsChartSharp } from "react-icons/io5";
import { CgArrowsExchangeAltV } from "react-icons/cg";
import { BiPhone } from "react-icons/bi";
import { Ri24HoursLine } from "react-icons/ri";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { MdOutlineOfflineBolt } from "react-icons/md";

const TechBoxes = () => {
  return (
    <div className="right__side-grid">
      <div>
        <IoStatsChartSharp className="vector" />

        <p>Data Tracking</p>
      </div>
      <div>
        <CgArrowsExchangeAltV className="vector" />

        <p>Conversion Tracking</p>
      </div>
      <div>
        <BiPhone className="vector" />

        <p>Lead & Call Tracking</p>
      </div>
      <div>
        <Ri24HoursLine className="vector" />

        <p>24/7 Campaign Data Access</p>
      </div>
      <div>
        <MdOutlineDashboardCustomize className="vector" />

        <p>Custom Data Reporting</p>
      </div>
      <div>
        <MdOutlineOfflineBolt className="vector" />

        <p>Oﬄine Conversion Tracking</p>
      </div>
    </div>
  );
};

export default TechBoxes;
