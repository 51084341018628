import React from "react";
import { FaCheck } from "react-icons/fa";
const Paid = ({handleModalClick}) => {
  return (
    <div className="solution__box paid">
      <h2 className="underlined-text">Paid Media</h2>
      <ul className="list-style-arrow">
        <li>
        <FaCheck id="check" /> 
          <a>
            Google Ads Management
          </a>
        </li>
        <li>
        <FaCheck id="check" /> 
          <a >
            Bing Microsoft Ads Management
          </a>
        </li>
        <li>
        <FaCheck id="check" /> 
          <a>
            Social Media Ads Management
          </a>
        </li>
        <li>
        <FaCheck id="check" /> 
          <a >
            Amazon Sponsored Advertising
          </a>
        </li>
        <li>
        <FaCheck id="check" /> 
          <a >
            Inﬂuencer Campaign Management
          </a>
        </li>
        <li>
        <FaCheck id="check" /> 
          <a>
            YouTube Ads Management
          </a>
        </li>
      </ul>
      <button onClick={handleModalClick}>Learn More</button>
    </div>
  );
};

export default Paid;
